import $get from 'lodash.get'
import EventEmitter from 'eventemitter3'

import LocalDB from './LocalDB'

class MediaPlayer extends EventEmitter {
  #content = null
  #episodeIndex = null
  #episodeId = null

  timeUpdateInterval = 100

  get content() {
    return this.#content
  }

  get episode() {
    if (this.content) {
      return this.content.$episode(this.#episodeIndex)
    }

    return null
  }

  get getEpisodeIndex() {
    if (!this.episode) {
      return 0
    }

    const currentEpisodeId = this.episodeId
    let savedIndex = 0
    this.#content.$episodes().forEach((ep, index) => {
      if (ep.id === currentEpisodeId) {
        savedIndex = index
      }
    })

    return savedIndex
  }

  get episodeId() {
    return this.#episodeId || 0
  }

  get episodeIndex() {
    return this.#episodeIndex || 0
  }

  get status() {
    return this.episode && this.episode.status
  }

  get volume() {
    return LocalDB.get('defvol', 1)
  }

  get inActivity() {
    return this.isLoading || this.isPlaying
  }

  get isError() {
    return this.episode && this.episode.isError
  }

  get isLoading() {
    return this.episode && this.episode.isLoading
  }

  get isLoaded() {
    return this.episode && this.episode.isLoaded
  }

  get isPaused() {
    return this.episode && this.episode.isPaused
  }

  get isPlaying() {
    return this.episode && this.episode.isPlaying
  }

  get isStopped() {
    return this.episode && this.episode.isStopped
  }

  get isUnload() {
    return this.episode && this.episode.isUnload
  }

  get isAudio() {
    return this.isVideo === false
  }

  get isVideo() {
    return this.episode && ['video', 'vimeo'].includes(this.episode.type)
  }

  get player() {
    return this.episode && this.episode.player
  }

  /**
   * @override
   * @param {string} eventName
   * @param {fn} cb
   * @todo list events
   * @description
   * supercharge the default EventEmitter.on method.
   * if a handler is registered on `content_ready` AFTER content and episodeIndex
   * has been registered, cb is immediately fired
   * content_ready is emitted via `setContent` method when a new content is
   * effectively set in the Mediaplayer instance
   */
  on(eventName, cb) {
    if (
      eventName === 'content_ready' &&
      this.#content !== null &&
      this.#episodeIndex !== null
    ) {
      cb(this.#content)
    }
    return super.on(eventName, cb)
  }

  /**
   * @param {number} index
   * @description
   * Record locally the loading of a new episode in the player.
   * Some actions are performed
   *  - load the current episode
   *  - triggering the "episode_updated" events
   *  - unload the previous loaded media object (if necessary)
   */
  defineEpisodeIndex(index) {
    const isNewIndex = this.#episodeIndex !== index
    const oldIndex = this.#episodeIndex

    // auto boot episode if exists
    if (isNewIndex && this.content.$episode(index)) {
      try {
        // must be placed before const episode assignment
        this.#episodeIndex = index
        const episode = this.content.$episode(index)
        const oldEpisode = this.content.$episode(oldIndex)

        // unlink old episode if exists and is not unloaded
        if (oldEpisode) {
          oldEpisode.unload()
        }

        // @todo (?)
        // create a new event "booterror" in models handled once before
        // load, if triggered and index + 1 exists, autoboot
        // the next episode (?)

        episode.load({
          preload: true,
        })

        // episode has been updated
        this.emit('episode_updated', this.episode)
      } catch (error) {
        console.log('error during boot', error)
      }
    }
    return this
  }

  pause() {
    if (this.episode) {
      this.episode.pause()
    }

    return this
  }

  async play() {
    if (this.episode) {
      await this.episode.play()
    }

    return this
  }

  stop() {
    if (this.episode) {
      this.episode.stop()
    }

    return this
  }

  isContent(content, episodeIndex = 0) {
    return (
      content &&
      this.#content &&
      this.#content.id === content.id &&
      this.#episodeIndex === episodeIndex
    )
  }

  isPlayingContent(content, episodeIndex) {
    return this.isPlaying && this.isContent(content, episodeIndex)
  }

  setContent(content, episodeIndex = 0) {
    // current media must not load the mediaplayer
    // possible reasons :
    // -> bad formated content
    // -> no audios
    // ! default = undefined
    if (content && content.shouldLoadMediaplayer === false) {
      return this
    }

    // prevent hard reset of content and episode
    // if setContent is called twice or more times
    if (this.isContent(content, episodeIndex)) {
      return this
    }

    // if a player is running, we unload the previous loaded sources
    // and we stop the currently playing player
    if (this.player) {
      this.episode.unload()
      this.content.$episodes().forEach((ep, epIndex) => {
        // we unload episode only if user wanna load a new content
        // or if given episode index is different
        if (
          content.id !== this.content.id ||
          (content.id === this.content.id && epIndex !== this.#episodeIndex)
        ) {
          ep.unload()
        }
      })
    }

    this.#content = content
    this.defineEpisodeIndex(episodeIndex)

    this.#episodeId = $get(this.episode, 'id', null)
    this.emit('content_ready', content)

    return this
  }

  setCurrentTime(time = 0) {
    if (this.episode) {
      this.episode.seek(time)
    }

    return this
  }

  setPercents(percent) {
    const audioDuration =
      this.episode.computedAudioDuration || this.episode.$metadata('duration')
    const time = audioDuration * (percent / 100)

    this.setCurrentTime(time)

    return this
  }

  setVolume(volume) {
    LocalDB.set('defvol', volume)

    if (this.episode) {
      this.episode.volume = volume
    }

    return this
  }
}

export default new MediaPlayer()
