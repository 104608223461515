import $get from 'lodash.get'
import $merge from 'lodash.merge'
import $clonedeep from 'lodash.clonedeep'

import { UnsupportedMethodError } from '../../utils/error'
import CommonContent from '../commons/Content'
import MediaPlayer from '../../services/MediaPlayer'
import { isArrayOfObject } from '../../utils/check-types'
import garbager from '../../utils/garbage-manager'
import warn from '../../utils/warning'
import DocumentAudio from './Audio'
import DocumentConversation from './Conversation'
import DocumentImage from './Image'
import DocumentMedia from './Media'
import DocumentVideo from './Video'

const computeAudiosDuration = (audios = []) => {
  return audios.reduce((prev, audio) => {
    return prev + audio.$metadata('duration', 0)
  }, 0)
}
const computeVideosDuration = (videos = []) => {
  return videos.reduce((prev, video) => {
    return prev + video.$metadata('duration', 0)
  }, 0)
}

export default class DocumentContent extends CommonContent {
  /**
   * @api private
   * options passed to mediaplayer for this content
   */
  #playOptions = {}

  /**
   * @getter
   * @type {Boolean}
   * @description `true` if the current content is played by MediaPlayer
   */
  get isPlaying() {
    return MediaPlayer.isPlayingContent(this)
  }

  $caption(selector) {
    if (typeof selector !== 'string') {
      throw new TypeError('selector should be a string')
    }

    const [slug, it] = selector.split(':')
    const caption = this.$metadata(slug)

    let res

    if (Array.isArray(caption)) {
      const captions = caption
        .filter(DocumentImage.isValid)
        .map((cap) => garbager(DocumentImage, cap))

      if (it) {
        switch (it) {
          case 'first':
            res = captions[0]
            break
          case 'last':
            res = captions[captions.length - 1]
            break
        }
      } else {
        res = captions
      }
    } else if (typeof caption === 'string') {
      res = garbager(DocumentImage, caption)
    }

    return res
  }

  $conversations(type) {
    return !type
      ? this.$data('conversations', [])
      : this.$data('conversations', []).find((conv) => conv.type === type)
  }

  $documents() {
    return this.$metadata('documents', [])
  }

  $episode(position) {
    const medias = this.$episodes()

    return medias[position] && typeof medias[position] === 'object'
      ? medias[position]
      : null
  }

  $episodes() {
    return $get(this.data, 'episodes', [])
  }

  $links() {
    return this.$metadata('links', [])
  }

  $sources() {
    warn(true, 'deprecated $sources, use $episodes instead')

    return this.$episodes()
  }

  /**
   * @todo
   * current limitation
   * conversations and subcontents are not rehydrated after the initial render
   */
  $rehydratation(data = {}) {
    const { metadatas = this.data.metadatas } = data

    // conversations is setup when we receive the conversations
    // object for the first time
    // if data.conversations is empty, we reseting the previous set data
    // this is a need, conversations can be fetched only if ?withConversations
    // parameter is set when dev make a query
    if (isArrayOfObject(data.conversations)) {
      data.conversations = data.conversations.map((conversation) => {
        return garbager(DocumentConversation, conversation).lean()
      })
    }

    let audios = $get(data, 'metadatas.audios', []).filter(
      DocumentAudio.isValid
    )

    const documents = $get(data, 'metadatas.documents', []).map((doc) =>
      garbager(DocumentMedia, doc, 'documents')
    )
    const links = $get(data, 'metadatas.links', []).map((doc) =>
      garbager(DocumentMedia, doc, 'links')
    )
    let videos = $get(data, 'metadatas.videos', []).filter(
      DocumentVideo.isValid
    )

    const sortedEpisodes = [...audios, ...videos]
      .sort((a, b) => {
        const posA = $get(a.metadatas, 'position', 9999) // missing position must be treated as max pos
        const posB = $get(b.metadatas, 'position', 9999) // missing position must be treated as max pos

        if (posA > posB) return 1
        if (posA < posB) return -1
        return 0
      })
      .map((doc, index) => {
        if (doc.type === 'audio') {
          return garbager(DocumentAudio, doc, this, index)
        } else if (doc.type === 'video' || doc.type === 'vimeo') {
          return garbager(DocumentVideo, doc, this, index)
        }
        return garbager(DocumentAudio, doc, this, index)
      })

    audios = sortedEpisodes.filter((episode) => episode.isAudio)
    videos = sortedEpisodes.filter((episode) => episode.isVideo)

    data.metadatas = $merge(metadatas, {
      audios,
      documents,
      links,
      videos,
    })

    const model = $clonedeep(this.modelProperties)
    const formated = $merge(model, data)

    return super.$rehydratation({
      ...formated,
      conversation: $get(data, 'conversations', []).find(
        (c) => c.type === 'comment'
      ),
      episodes: sortedEpisodes,
      episodesDuration:
        computeAudiosDuration(audios) + computeVideosDuration(videos),
      videos,
    })
  }

  /**
   * @api public
   */
  pause() {
    return MediaPlayer.pause()
  }

  /**
   * @api public
   */
  play(episodeIndex = 0, options) {
    this.#playOptions = options

    MediaPlayer.setContent(this, episodeIndex, this.#playOptions)
    return MediaPlayer.play()
  }

  /**
   * @api public
   */
  hasNext(currentEpisodeIndex) {
    return !!this.$episode(currentEpisodeIndex + 1)
  }

  /**
   * @api public
   * Will try to play the episode after the given one if it exists
   */
  next(currentEpisodeIndex) {
    if (this.hasNext(currentEpisodeIndex)) {
      if (MediaPlayer.isLoading || MediaPlayer.isPlaying) {
        MediaPlayer.stop()
      }
      const episode = this.$episode(currentEpisodeIndex + 1)

      if (episode.isVideo) {
        MediaPlayer.once('booted', () => MediaPlayer.play())
      }

      MediaPlayer.setContent(this, currentEpisodeIndex + 1, this.#playOptions)

      if (episode.isAudio) {
        MediaPlayer.play()
      }
    }
  }

  /**
   * @api public
   */
  prev() {
    if (this.$episode(MediaPlayer.episodeIndex - 1)) {
      MediaPlayer.stop()
      MediaPlayer.setContent(
        this,
        MediaPlayer.episodeIndex - 1,
        this.#playOptions
      )
      return MediaPlayer.play()
    }
  }

  /**
   * @api public
   */
  replay() {
    return this.seek(0).play()
  }

  /**
   * @api public
   * @param {number} time
   */
  seek(time = 0) {
    return MediaPlayer.setCurrentTime(time)
  }

  /**
   * @api public
   */
  stop() {
    return MediaPlayer.stop()
  }

  delete() {
    throw new UnsupportedMethodError('delete')
  }

  post() {
    throw new UnsupportedMethodError('post')
  }

  put() {
    throw new UnsupportedMethodError('put')
  }
}
