const $merge = require('lodash.merge')

const config = require('./config.base')
const loadShell = require('./utils/voicerconfig/load-shell')

const configEnvironments = {
  development: require('./config.development'),
  staging: require('./config.staging'),
  production: require('./config.production'),
  next: require('./config.next'),
}

let configLocal = require('./utils/voicerconfig/load-local')
let envConfig = null

if (process.env.ADD_CONFIG_OPTIONS) {
  try {
    console.log(`🤓 use environment options`)
    envConfig = JSON.parse(process.env.ADD_CONFIG_OPTIONS)
  } catch (error) {
    console.error(`Invalid environment options`)
    console.error(`You passed`, process.env.ADD_CONFIG_OPTIONS)
    console.error(error)
    process.exit(1)
  }
}

const transformConfig = (config) => {
  if (config.useShell) {
    config = loadShell(config)
  }
  return config
}

/**
 * default configuration file
 * common no matter the BROCOLI_API_MODE
 * you can override properties in
 * config.[BROCOLI_API_MODE].js configurations files
 */
const endConfig = $merge({},
  config, {
    /**
     * common configuration here
     */
     siteId: '5e85a8824cb91271f2b816a3',
     enableSplashScreen: 'wavy',
     enableExperimentalBlocks: true,
     showCardDescription: true,
     disabledComponents: ['wButtonLike', 'wTagLikeCount','wCardsEngagementLabels'],

     authMode: [
      'local',
      {
        mode: 'unique_password',
        defaultLogin: 'digital+macif@mediameeting.fr',
      },
    ],
     metadatas: {
       name: 'Radio M',
       colorPrimary: '#051D54',
       supportEmailAddress: 'support@radio-macif.fr',
     },
     analytics: {
       ga: 'UA-111736601-35',
       mixpanel: '39610e5ba51ee9b575f16978bea5d551',
       matomo: '23',
     },
     useLTS: true,
     useShell: [
       'bb-default',
       {
         mode: 'universes',
         universes: [
          {
            type: 'tag',
            tagId: '61e841404ec828c8631bcd35',
            i18n: {
              fr: {
                title: "Voix Macif, c'est vous",
                description: `Challenge interne`,
              },
            },
            heading: {
              color: '#00C9F6',
              canRadio: false,
              dark: true,
              buttonColor: 'white',
              buttonLight: true,
            },
          },
           {
             type: 'tag',
             tagId: '5f4f64bb0fc1bc6d1c6fb1fe',
             i18n: {
               fr: {
                 title: 'Émissions des dirigeants',
               },
             },
             heading: {
               color: '#051D54',
               canRadio: false,
               dark: true,
               buttonColor: 'white',
               buttonLight: true,
             },
           },
           {
             type: 'tag',
             tagId: '6144aac2315c0457fec5ada1',
             i18n: {
               fr: {
                 title: 'Ma Préférence',
               },
             },
             heading: {
               color: '#FB9AA3',
               canRadio: false,
               dark: true,
               buttonColor: 'white',
               buttonLight: true,
             },
           },
           {
             type: 'tag',
             tagId: '603f82cc3052ac27d52f1c4e',
             i18n: {
               fr: {
                 title: 'Chaîne de l’engagement',
               },
             },
             heading: {
               color: '#FF866E',
               canRadio: false,
               dark: true,
               buttonColor: 'white',
               buttonLight: true,
             },
           },
           {
            type: 'tag',
            tagId: '5fc63e7e3af637ba76f76f78',
            i18n: {
              fr: {
                title: 'M le Mag',
              },
            },
            heading: {
              color: '#52DFCE',
              canRadio: false,
              dark: true,
              buttonColor: 'white',
              buttonLight: true,
            },
          },
           {
             type: 'tag',
             tagId: '5f4f64b10fc1bc0c526fb1fd',
             i18n: {
               fr: {
                 title: 'Destinations solidaires',
               },
             },
             heading: {
               color: '#cbdb16',
               canRadio: false,
               dark: true,
               buttonColor: 'white',
               buttonLight: false,
             },
           },
           {
             type: 'tag',
             tagId: '5f4f64c30fc1bc44af6fb1ff',
             i18n: {
               fr: {
                 title: '60 ans',
               },
             },
             heading: {
               color: '#051D54',
               canRadio: false,
               dark: true,
               buttonColor: 'white',
               buttonLight: false,
             },
           },
         ],
         _event: new Date('2022-01-31T12:59:59.999Z'),
        _eventSpan: 1 * 60 * 60 * 1000, // 1h in ms
       },
     ],
      },
  configEnvironments[process.env.BROCOLI_API_MODE],
  configLocal,
  envConfig
)

module.exports = transformConfig(endConfig)
