<template>
  <div>
    <v-dialog v-model="isVisible" width="420" max-width="100%">
      <v-card>
        <v-card-title class="title" primary-title>
          {{ $t('cp_wrgpdmodal_title') }}
        </v-card-title>
        <v-card-text class="body-1">
          <i18n path="cp_wrgpdmodal_text" tag="div">
            <template #sitename>
              <span class="primary--text">{{ siteName }}</span>
            </template>
            <template #pol_link>
              <nuxt-link
                to="/data/personal-data"
                v-text="$t('cp_wrgpdmodal_datalink')"
              />
            </template>
            <template #cp_wrgpdmodal_usage>
              <br />
              <br />
              <ul>
                <li v-text="$t('cp_wrgpdmodal_usage_opts_1')"></li>
                <li v-text="$t('cp_wrgpdmodal_usage_opts_2')"></li>
                <li v-text="$t('cp_wrgpdmodal_usage_opts_3')"></li>
              </ul>
              <br />
            </template>
          </i18n>
        </v-card-text>
        <v-card-actions>
          <v-btn
            :icon="$voicer.isMobile"
            :depressed="$voicer.isDesktopOrTablet"
            nuxt
            to="/settings"
          >
            <v-icon v-if="$voicer.isMobile" color="grey">mdi-cog</v-icon>
            <span v-else>{{ $t('glob_btn_settings') }}</span>
          </v-btn>
          <v-spacer />
          <v-btn depressed color="primary" @click="handleRefuse">
            {{ $t('glob_btn_refuse') }}
          </v-btn>
          <v-btn depressed color="primary" @click="handleAccept">
            {{ $t('glob_btn_accept') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import localstore from 'store'
import { mapState, mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      siteName: 'root/siteName',
    }),
    ...mapState({
      open(state) {
        return state.root.controlledRGPD === false
      },
    }),
    isControllable() {
      return ['data-id', 'settings'].includes(this.$route.name) === false
    },
    isVisible: {
      get() {
        return this.open
      },
      set(v) {
        if (v === false) {
          this.handleRefuse()
        }
      },
    },
  },

  watch: {
    '$route.name'(currentName) {
      if (currentName === 'data-id' || currentName === 'settings') {
        const hasControlled = localstore.get(
          'acceptedRGPD',
          !localstore.get('refusedRGPD', navigator.doNotTrack === '1')
        )
        this.$store.dispatch('root/setControlledRGPD', hasControlled)
      }
    },
  },

  methods: {
    handleRefuse() {
      this.$store.dispatch('root/disableMetricsTrackers')
    },
    handleAccept() {
      this.$store.dispatch('root/enableMetricsTrackers')
    },
  },
}
</script>
