<template>
  <div class="w-block__pre-featured">
    <!-- pre-featured block -->
    <portal to="before-all">
      <v-sheet
        v-if="eventDate"
        class="w-block__pre-featured__topblock pt-2 pb-2"
        color="#00C9F6"
        dark
        tile
      >
        <v-container class="pa-1">
          <div class="subtitle font-weight-bold">
            Lancement du challenge "Voix Macif c'est vous" dans
            {{ eventCountdown }}
          </div>
        </v-container>
      </v-sheet>
    </portal>
  </div>
</template>

<script>
function formatTimeCountdown(seconds) {
  seconds = seconds || 1
  seconds = Number(seconds)
  seconds = Math.abs(seconds)

  const d = Math.floor(seconds / (3600 * 24))
  const h = Math.floor((seconds % (3600 * 24)) / 3600)
  const m = Math.floor((seconds % 3600) / 60)
  const s = Math.floor((seconds % 60) * 1)
  const parts = []

  if (d > 0) {
    parts.push(d + ' jour' + (d > 1 ? 's' : ''))
  }

  if (h > 0) {
    parts.push(h + ' heure' + (h > 1 ? 's' : ''))
  }

  if (m > 0) {
    parts.push(m + ' minute' + (m > 1 ? 's' : ''))
  }
  if (s > 0) {
    parts.push(s + ' seconde' + (s > 1 ? 's' : ''))
  }
  return parts.join(', ')
}

export default {
  data() {
    return {
      eventActive: false,
      eventCountdown: null,
    }
  },
  computed: {
    eventDate() {
      return this.$voicer.getShellConfig('_event')
    },
    eventSpan() {
      return this.$voicer.getShellConfig('_eventSpan')
    },
    isVisible() {
      return this.$route.name === 'index'
    },
  },
  mounted() {
    this.computeEventCountdown()
  },
  methods: {
    computeEventCountdown() {
      if (!this.eventDate) {
        return false
      }

      const now = +new Date()
      const eventDate = this.eventDate
      const diff = Math.floor(eventDate - now)
      const diffEnd = diff + this.eventSpan

      if (diff < 0 && diffEnd < 0) {
        this.eventActive = false
        return
      }

      if (diff < 0 && diffEnd > 0) {
        this.eventActive = true
        this.eventCountdown = formatTimeCountdown(diffEnd / 1000)
      } else if (diff > 0) {
        this.eventActive = false
        this.eventCountdown = formatTimeCountdown(diff / 1000)
      }

      setTimeout(() => {
        this.computeEventCountdown()
      }, 500)
    },
  },
}
</script>

<style lang="scss" scoped>
.w-block__pre-featured__topblock {
  display: flex;
  justify-content: center;
  text-align: center;
}
</style>
