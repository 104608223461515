/**
 * @api public
 * @class
 * Events
 *
 * @description
 * Provide a simple function called `emit` which
 * accumulate and per batch
 */

class EventManager {
  /**
   * @description
   * will be fulfilled by hypotetical emit() calls sent
   * before spoke is ready
   * @todo
   * complete when browser is offline
   */
  #pendingStack = []

  /**
   * @description
   * Execute the pending stack (reemit)
   * Should not be blocking
   * @returns {instance}
   */
  executePendingStack() {
    this.#pendingStack.forEach(({ name, data }) => {
      this.emit(name, data)
    })
    this.#pendingStack = []

    return this
  }

  /**
   *
   * @param {string} name
   * @param {object} data
   * @returns {Promise}
   */
  emit(name, data) {
    let dataraw

    if (this.__ready === false) {
      // spoke not ready, prevent anonymous call
      this.#pendingStack.push({ name, data })
      return Promise.resolve()
    }

    try {
      // DEBUG
      // if (process.env.NODE_ENV === 'development') {
      //   console.log('[eventmanager:emit]', name, { data })
      // }
      if (data) {
        dataraw = Buffer.from(JSON.stringify(data))
      }

      return this.__http.post(`/events`, {
        name,
        data: dataraw.toString('base64'),
      })
    } catch (error) {
      // @todo
      // develop a retry strategy here and on analytics events
      console.warn(`Unprocessable eventmanager data payload`)
      // console.warn({ data })
      console.warn(error)
    }
  }
}

export default new EventManager()
