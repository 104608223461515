import $merge from 'lodash.merge'

import { UnsupportedMethodError } from '../../utils/error'
import isBrowser from '../../utils/is-browser'
import warn from '../../utils/warning'
import Model from '../../services/Model'
import EventManager from '../../services/EventManager'
import Realtime from '../../services/Realtime2'

/**
 * @class BaseMedia
 * @extends Model
 * @description
 * Media system model
 */
const DEFAULT_MODEL = {
  type: '',
  value: '',
  metadatas: {},
}

export default class BaseMedia extends Model {
  static modelName = 'Media'
  static modelProperties = DEFAULT_MODEL
  static resource = 'medias'

  /**
   * @api private
   * @type {string}
   * @description related metadata (media always depending of metadata)
   * eg: audios, documents, links, videos...
   * @doc https://brocoli.io/#/principles/metadatas/reserved
   */
  #metadata = null

  constructor(data, metadata) {
    super('medias', $merge({}, DEFAULT_MODEL, data))
    this.#metadata = metadata
  }

  $download(filename = null) {
    if (!isBrowser) {
      warn(true, '$download is not supported on non-browser environments')

      return this
    }
    const node = document.createElement('a')

    if (node && node.setAttribute) {
      node.setAttribute('href', this.source)
      node.setAttribute('download', filename || this.title)
      node.setAttribute('target', '_blank')

      document.body.appendChild(node)
      node.addEventListener('click', () => {
        EventManager.emit('download_document', {
          ref: this.id,
          metadatas: this.data.metadatas,
          type: this.type,
          url: this.source,
        })
      })
      node.click()
    }

    return this
  }

  $open(target = '_blank', ...options) {
    if (!isBrowser) {
      warn(true, '$open is not supported on non-browser environments')

      return this
    }

    // eslint-disable-next-line
    const IS_URL = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/gim
    const url = String(this.source)

    if (IS_URL.test(url)) {
      Realtime.publish('event', {
        name: 'click_link',
        ref: this.id,
      })
      window.open(this.source, target, ...options)
    } else {
      warn(true, `${url} is not a valid URL`)
    }

    return this
  }

  exists() {
    return !!this.id
  }

  get humanType() {
    switch (this.type) {
      case 'video':
      case 'vimeo':
        return 'video'
      case 'audio':
        return 'audio'
      default:
        return this.type
    }
  }

  get position() {
    return this.$metadata('position', 0)
  }

  get publishedAt() {
    return this.$metadata(
      'publishedAt',
      this.$metadata('createdAt', new Date())
    )
  }

  get source() {
    return this.$data('value')
  }

  get title() {
    const extensions = [
      'mp3',
      'mp4',
      'jpg',
      'ogg',
      'png',
      'm4a',
      'webm',
      'pdf',
      'txt',
      'png',
      'img',
      'doc',
    ]
    const title = this.$metadata(
      'title',
      this.#metadata === 'links' ? this.source : undefined
    )

    if (!title) {
      return title
    }

    const titleExt = /(?:\.([^.]+))?$/.exec(title)

    if (titleExt.length === 2) {
      if (extensions.includes(titleExt[1])) {
        return undefined
      }
    }

    return title
  }

  get type() {
    return this.$metadata('mimetype', this.data.type)
  }

  get() {
    throw new UnsupportedMethodError('get')
  }

  delete() {
    throw new UnsupportedMethodError('delete')
  }

  post() {
    throw new UnsupportedMethodError('post')
  }

  put() {
    throw new UnsupportedMethodError('put')
  }
}
