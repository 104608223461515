import $get from 'lodash.get'
import ContentModel from '~/@mediam/spoke-js/models/brocoli/Content'

function uniq(a) {
  const seen = {}
  return a.filter((item) => {
    return seen[item.id] ? false : (seen[item.id] = true)
  })
}

function shouldOpenPlayerPersistant(app, content) {
  return (
    app.$voicer.getConfig('disableAutosetPlayerContent') === false &&
    ['live', 'podcast', 'video'].includes(content.type)
  )
}

function processFeaturedContents(
  { commit, dispatch, rootState },
  contents,
  app
) {
  if (contents.length) {
    commit('SET_FEATURED_CONTENTS', contents)
    const content = contents[0]

    if (shouldOpenPlayerPersistant(app, content)) {
      if (!rootState.player.content.id) {
        dispatch(
          'player/setPlayerContent',
          { content },
          {
            root: true,
          }
        )
      }
      dispatch('player/openPersistantPlayer', null, {
        root: true,
      })
    }
  } else {
    commit('SET_FEATURED_CONTENT_LOADING', false)
  }
}

export default {
  state: {
    alternativeContents: [],
    alternativeContentsIsLoading: false,
    collectionsContents: {},
    collectionsContentsIsLoading: false,
    collections: [],
    collectionsMetas: {},
    featuredContentIsLoading: false,
    featuredContents: [],
    listContents: [],
    listContentsMetas: {},
    listContentsIsLoading: false,
    radioCollection: {},
    radioContents: [],
    radioIsLoading: false,
    /**
     * some explanations here: in case you are in "solo" mode, the main content is first loaded
     * in "teaserContent". "soloContent" remains null as long as the user has not selected an
     * "alternative" content (config.alternativeContents).
     * If an alternative content is selected, "soloContent" will contain the content object to
     * display on the home page (teaserContent is ignored if soloContent is not null).
     */
    teaserContent: {},
    teaserContentIsLoading: false,
    teaserContentNotExists: false,
    soloContent: null,
  },
  actions: {
    /**
     * @method createRadio
     * @returns {promise}
     * @description
     * will instantiate a new radio from a given collection.
     * the radio will load the 10th content, then make a second (heavier) request in
     * order to load the next 20 contents (so as to create a fairly large playlist).
     * an event handler will be added to the mediaplayer in order to detect the end of a content.
     */
    createRadio({ commit, dispatch, state, rootState }, { collection }) {
      const identifier = collection.id || collection.tagId
      const contents = state.collectionsContents[identifier]
      commit('SET_RADIO_COLLECTION', collection)
      commit('SET_RADIO_CONTENTS', contents)
      commit('player/SET_RADIO_PLAYER_VISIBLE', true, { root: true })

      if (contents.length === 0) {
        // todo: can't create a radio with zero contents - show a snackbar here
        return
      }

      const content = contents[0]
      if (rootState.player.content.id && rootState.player.status === 'play') {
        // todo: show notice here, the radio will start after the current content
        commit('SET_RADIO_CONTENTS', [rootState.player.content, ...contents])
      } else {
        dispatch(
          'player/setPlayerContent',
          { content },
          {
            root: true,
          }
        )
        dispatch('player/forceOpenPersistantPlayer', null, {
          root: true,
        })
      }

      const sleep = (ms) => {
        return new Promise((resolve) => setTimeout(resolve, ms))
      }

      const startAsyncLoadContents = async (options) => {
        try {
          const res = await dispatch('getCollectionContent', {
            ...collection,
            ...options,
          })
          if (res.meta.more === true) {
            await sleep(300)
            await startAsyncLoadContents({
              offset: options.offset + 10,
            })
          }
        } catch (error) {
          // todo: something to do here
        }
      }

      startAsyncLoadContents({ offset: contents.length })
    },

    /**
     * @method getAlternativeContents
     * @returns {promise}
     */
    getAlternativeContents({ commit }) {
      // load alt contents
      const altIds = this.$voicer.getShellConfig('alternativeContents', [])
      const spoke = this.app.$spoke

      if (altIds.length) {
        commit('SET_ALTERNATIVE_CONTENTS_LOADING', true)

        const promises = altIds.map((altId) => {
          const pseudoQuery = spoke.collection('contents')

          return new Promise((resolve) => {
            pseudoQuery
              .getOne(altId)
              .lean()
              .on('success', (content) => {
                commit('PUSH_ALTERNATIVE_CONTENT', content)
                resolve()
              })
              .on('error', (e) => {
                resolve()
              })
          })
        })

        return Promise.all(promises)
          .then(() => {
            commit('SET_ALTERNATIVE_CONTENTS_LOADING', false)
          })
          .catch((e) => {
            commit('SET_ALTERNATIVE_CONTENTS_LOADING', false)
          })
      }

      return Promise.resolve()
    },

    /**
     *
     */
    getCollections({ commit, dispatch }) {
      const spoke = this.app.$spoke

      return spoke.http
        .get(`/collections`)
        .then((data) => {
          commit('SET_COLLECTIONS', data.items)
          return data
        })
        .catch((err) => {
          // there is an error here dude
          console.error(err)
        })
    },

    /**
     * @method getCollectionsContents
     * @returns {promise}
     * @description
     * Fetch contents for each availables collections
     * reminder: a collection can be :
     *  - a segment with isCollection=true
     *  - a tag
     * @note
     * This method is non-blocking
     */
    getCollectionsContents({ commit, dispatch }) {
      this.getters['auth/collections'].forEach((collection) => {
        const collectionIdentifier = collection.id || collection.tagId

        dispatch('getCollectionContent', {
          collectionId: collection.id,
          tagId: collection.tagId,
          params: {
            limit: this.$voicer.isMobile ? 3 : 4,
          },
        }).then((data) => {
          const { items: contents } = data
          commit('SET_COLLECTION_CONTENTS', {
            identifier: collectionIdentifier,
            contents: contents.map((content) =>
              new ContentModel(content).lean()
            ),
          })

          return data
        })
      })

      return Promise.resolve()
    },

    /**
     *
     */
    getCollectionContent({ commit }, options) {
      if (!options.collectionId && !options.tagId) {
        throw new Error('a collection must have a collectionId or a tagId')
      }

      if (!options.params) {
        options.params = {
          offset: 0,
          limit: 10,
        }
      }

      const spoke = this.app.$spoke

      commit('SET_COLLECTION_CONTENTS_LOADING', true)

      const remoteUrl = options.collectionId
        ? `/collections/${options.collectionId}/contents`
        : `/contents`

      function parseStringToArrayMaybe(str) {
        if (str.indexOf(',')) {
          return str.split(',')
        }
        return str
      }

      if (options.tagId) {
        let tags = options.params.tags || []
        tags = Array.isArray(options.tagId)
          ? tags.concat(options.tagId)
          : tags.concat(parseStringToArrayMaybe(options.tagId))
        tags = [...new Set(tags)] // <== remove duplicated tags
        options.params = { ...options.params, tags }
      }

      return spoke.http
        .get(remoteUrl, {
          params: {
            ...options.params,
          },
        })
        .then((data) => {
          return data
        })
        .catch((err) => {
          // there is an error here dude
          console.error(err)
          commit('SET_COLLECTION_CONTENTS_LOADING', false)
        })
    },

    /**
     *
     */
    getCollectionContentsList(
      { commit, dispatch, state, rootState },
      { searchCollectionIdentifier, collectionId, tagId, offset = 0 }
    ) {
      const identifier = searchCollectionIdentifier || collectionId || tagId

      if (
        $get(state, `collectionsContents[${identifier}]`, []).length !== 0 &&
        !searchCollectionIdentifier
      ) {
        offset = state.collectionsContents[identifier].length
      }
      const params = {}

      if (tagId) {
        params.tags = Array.isArray(tagId) ? tagId : tagId.split(',')
      }

      if ($get(rootState, 'filters.query.q', []).length !== 0) {
        params.tags = (params.tags || []).concat(rootState.filters.query.q)
      }
      if (
        searchCollectionIdentifier &&
        rootState.filters?.query?.month &&
        rootState.filters?.query?.year
      ) {
        params.month = rootState.filters.query.month
        params.year = rootState.filters.query.year
      }
      return dispatch('getCollectionContent', {
        collectionId,
        tagId,
        params: {
          ...params,
          offset,
          limit: 10,
        },
      }).then((data) => {
        const { items: contents } = data
        let arrContents = [
          ...contents.map((content) => new ContentModel(content).lean()),
        ]
        if (!searchCollectionIdentifier) {
          arrContents = [
            ...(state.collectionsContents[identifier] || []),
            ...arrContents,
          ]
        }

        commit('SET_COLLECTION_CONTENTS', {
          identifier,
          contents: arrContents,
        })

        return data
      })
    },

    /**
     * bubblecast limitation
     * pre-called methods must returns a promise
     * @method getFeaturedContents
     * @returns {promise}
     * @description
     * Fetch the featured content (API query)
     */
    getFeaturedContents({ commit, dispatch, rootState }) {
      const spoke = this.app.$spoke
      const pseudoQuery = spoke.collection('contents')
      const isFeaturedPseudoQuery = spoke.collection('contents')

      const limit = 3

      commit('SET_FEATURED_CONTENT_LOADING', true)

      return new Promise((resolve) => {
        pseudoQuery
          .get({
            // below an usefull option
            limit,
            ...this.app.$voicer.getShellConfig(
              'view.featuredContentsOptions',
              {}
            ),
          })
          .on('success', (contentsPa) => {
            const contents = contentsPa.toArray()

            isFeaturedPseudoQuery
              .get({
                // below an usefull option
                isFeatured: true,
                limit,
                ...this.app.$voicer.getShellConfig(
                  'view.featuredContentsOptions',
                  {}
                ),
              })
              .on('success', (contentsPa) => {
                const featuredContents = contentsPa.toArray()
                let mergedContent = [...contents]
                if (featuredContents.length) {
                  mergedContent = [...featuredContents, ...contents].slice(
                    0,
                    limit
                  )
                }
                processFeaturedContents(
                  { commit, dispatch, rootState },
                  mergedContent,
                  this.app
                )
                resolve()
              })
              .on('error', (e) => {
                processFeaturedContents(
                  { commit, dispatch, rootState },
                  contents,
                  this.app
                )
                console.error(e)
                resolve()
              })
          })
          .on('error', (e) => {
            console.error(e)
            resolve()
          })
      })
    },

    /**
     *
     */
    getListContents({ commit, dispatch, state }, offset = 0) {
      const options = {
        params: {
          offset,
          limit: 10,
        },
      }

      const spoke = this.app.$spoke

      commit('SET_LIST_CONTENTS_LOADING', true)

      return spoke.http
        .get('/contents', {
          params: {
            ...options.params,
          },
        })
        .then((data) => {
          const { items: contents } = data
          const arrContents = [
            ...contents.map((content) => new ContentModel(content).lean()),
          ]

          commit('SET_LIST_CONTENTS', arrContents)

          return data
        })
        .catch((err) => {
          // there is an error here dude
          console.error(err)
          commit('SET_LIST_CONTENTS_LOADING', false)
        })
    },

    /**
     * @method getSpecificContent
     * @returns {promise}
     * @description
     * Fetch a specific content (API query)
     */
    getSpecificContent({ commit, dispatch }) {
      const spoke = this.app.$spoke
      const pseudoQuery = spoke.collection('contents')

      commit('SET_TEASER_CONTENT_LOADING', true)
      const contentId = this.app.$voicer.getShellConfig('contentId')

      return new Promise((resolve) => {
        pseudoQuery
          .getOne(contentId)
          .lean()
          .on('success', (content) => {
            commit('SET_TEASER_CONTENT', content)

            if (shouldOpenPlayerPersistant(this.app, content)) {
              dispatch(
                'player/setPlayerContent',
                { content },
                {
                  root: true,
                }
              )
              dispatch('player/forceOpenPersistantPlayer', null, {
                root: true,
              })
            }
            resolve()
          })
          .on('error', (e) => {
            commit('SET_TEASER_CONTENT_NOT_EXISTS', true)
            resolve()
          })
      })
    },
  },
  mutations: {
    SET_ALTERNATIVE_CONTENTS_LOADING(state, status = true) {
      state.alternativeContentsIsLoading = status
    },
    PUSH_ALTERNATIVE_CONTENT(state, content) {
      state.alternativeContents = uniq([...state.alternativeContents, content])
    },
    SET_COLLECTIONS(state, collections) {
      state.collections = [...collections]
    },
    SET_COLLECTION_CONTENTS(state, { identifier, contents }) {
      state.collectionsContentsIsLoading = false
      state.collectionsContents = {
        ...state.collectionsContents,
        [identifier]: uniq([...contents]),
      }
    },
    SET_COLLECTION_CONTENTS_LOADING(state, status = true) {
      state.collectionsContentsIsLoading = status
    },
    SET_COLLECTION_META(state, { collectionId, meta }) {
      state.collectionsMetas = {
        ...state.collectionsMetas,
        [collectionId]: meta,
      }
    },
    SET_FEATURED_CONTENTS(state, contents) {
      state.featuredContentIsLoading = false
      state.featuredContents = uniq([...contents])
    },
    SET_FEATURED_CONTENT_LOADING(state, status = true) {
      state.featuredContentIsLoading = status
    },
    SET_LIST_CONTENTS_LOADING(state, status = true) {
      state.listContentsIsLoading = status
    },
    SET_LIST_CONTENTS(state, contents) {
      state.listContents = uniq([...state.listContents, ...contents])
    },
    SET_RADIO_COLLECTION(state, collection) {
      state.radioCollection = { ...collection }
    },
    SET_RADIO_CONTENTS(state, contents) {
      state.radioContents = uniq([...contents])
    },
    SET_SOLO_CONTENT(state, content) {
      state.soloContent = content
    },
    SET_TEASER_CONTENT(state, content) {
      state.teaserContentIsLoading = false
      state.teaserContent = content
    },
    SET_TEASER_CONTENT_LOADING(state, status = true) {
      state.teaserContentIsLoading = status
    },
    SET_TEASER_CONTENT_NOT_EXISTS(state, status = true) {
      state.teaserContentNotExists = status
    },
  },
  getters: {
    alternativeContents(state) {
      return state.alternativeContents
    },
    collections(state) {
      return state.collections
    },
    collectionsContents(state) {
      return state.collectionsContents
    },
    collectionsContentsIsLoading(state) {
      return state.collectionsContentsIsLoading
    },
  },
  middlewares: {},
}
