import $merge from 'lodash.merge'
import { Howler, Howl } from 'howler'

import EventManager from '../../services/EventManager'
import MediaPlayer from '../../services/MediaPlayer'
import Realtime from '../../services/Realtime2'
import { CoreError } from '../../utils/error'
import { isObject } from '../../utils/check-types'
import nextTick from '../../utils/next-tick'
import CommonMedia from './Media'

/**
 * @class Audios
 * @extends Model
 * @description
 * Audio model
 *
 * @todo
 * implement play method
 * User:play -> Server:authentify & generate tmp token -> User:fetch media with token
 *
 * @todo
 * should be commented (good first commit)
 */
const DEFAULT_MODEL = {
  value: '',
  type: 'audio',
  metadatas: {
    duration: 0,
    position: 0,
    title: 'Unknown',
  },
}

// note(dev):
// `play` must be omitted here
// there is some case where howler
// will call `play` multiple times
// a trick is made in `load` to prevent
// multiple invocations
const HOWLER_EVENTS = [
  'load',
  'loaderror',
  'playerror',
  // 'end', is manually called
  'pause',
  'stop',
  'mute',
  'volume',
  'rate',
  'seek',
  'fade',
  'unlock',
]

class CommonAudio extends CommonMedia {
  static modelName = 'Audio'
  static modelDefaults = DEFAULT_MODEL

  /**
   * @api private
   * @description
   * attached content (yes, it's a circular reference)
   */
  #content = null

  /**
   * @api private
   * @description
   * true if play was init but is not currently effective
   */
  #isPlayBooting = false

  /**
   * @api private
   * @description
   * episode index (array  index in the parent content epideos property)
   */
  #episodeIndex = 0

  /**
   * @api private
   * @description
   * last updated realtime status
   * @type {string}
   */
  #lastRTStatus = null

  /**
   * @api private
   * @see load method
   * @see Howler
   */
  #player = null

  /**
   * @api private
   * @description
   * media played at (last op only)
   */
  #mediaPlayAt = null

  /**
   * @api private
   * @description
   * media stoped at (last op only)
   */
  #mediaStopAt = null

  /**
   * @api public
   * @description
   * status of the current player
   * can have the following values
   * - unload
   * - loading
   * - load
   * - error
   * - pause
   * - play
   * - stop
   * default value is `unload`
   */
  status = 'unload'

  /**
   * @api public
   * @description
   * computed when audio file is loaded (see event load)
   * and used to compute the progression percentage of
   * read when the media's playing
   */
  computedAudioDuration = 0

  /**
   * @api public
   * @description
   * mutex set to `true` if an audio file has been launched
   * this mutex is never reset, so, if a media is played and
   * the reading is finished, this mutex is always `true`
   */
  hasBeenLaunched = false

  /**
   * @api public
   * @description
   * mutex set to `true` if a media is launched and has it
   * current time greater than 0
   * this mutex is reset when the media is stoped or ended
   */
  isLaunched = false

  static resource = 'audios'

  get _player() {
    return this.#player
  }

  get content() {
    return this.#content
  }

  get currentTime() {
    return this.#player ? this.#player.seek() : 0
  }

  get duration() {
    return this.$metadata('duration', 0)
  }

  get episodeIndex() {
    return this.#episodeIndex
  }

  get progress() {
    let time = 0
    let percent = 0

    if (this.#player) {
      time = this.currentTime
      percent = time / this.computedAudioDuration
    }

    return {
      time,
      percent,
    }
  }

  get isError() {
    return ['error', 'loaderror', 'playerror'].includes(this.status)
  }

  get isLoading() {
    return this.status === 'loading'
  }

  get isLoaded() {
    return (
      ['loading', 'error', 'loaderror', 'unload'].includes(this.status) ===
      false
    )
  }

  get isPaused() {
    return this.status === 'pause'
  }

  get isPlaying() {
    return this.status === 'play'
  }

  get isStopped() {
    return this.status === 'stop'
  }

  get isUnload() {
    return this.status === 'unload'
  }

  get isAudio() {
    return true
  }

  get isVideo() {
    return false
  }

  get player() {
    return this.#player
  }

  set volume(value) {
    if (this.player) {
      this.player.volume(value)
    }
  }

  get volume() {
    if (this.player) {
      return this.player.volume()
    }
    return 0
  }

  constructor(data, content, index) {
    super($merge({}, DEFAULT_MODEL, data))

    this.#content = content
    this.#episodeIndex = index

    this.on('error', (error) => {
      const payload = {
        id: this.id,
        mediaDuration: this.duration,
        mediaSource: this.$data('value'),
        mediaTitle: this.$metadata('title', 'unknown'),
        error,
      }
      EventManager.emit('media_error', payload)
    })
  }

  propagateEvent(eventName, context) {
    return (data) => {
      context.emit(eventName, data)
    }
  }

  dispatchEvent(eventName) {
    return (data) => {
      this.propagateEvent(eventName, this)(data)
      this.propagateEvent(
        eventName,
        MediaPlayer
      )({ content: this.#content, data })
    }
  }

  /**
   * @description
   * send a signal through ws/http to atomatically
   * update the current media status
   * why? prevent double-calls and centralize the async ops mechanism
   * @param {string} status
   * @param {object?} data
   */
  asyncStatusAtomicUpdate(status, data) {
    const opAt = new Date()

    if (status !== this.#lastRTStatus) {
      this.#lastRTStatus = status

      Realtime.publish(
        'track',
        status === 'play' ? data : { media: { status } }
      )

      if (status === 'play') {
        this.#mediaPlayAt = opAt

        const payload = {
          id: this.id,
          mediaDuration: this.duration,
          mediaTitle: this.$metadata('title', 'unknown'),
          mediaSource: this.$data('value'),
        }
        EventManager.emit('media_play', payload)
      }

      if (status === 'stop') {
        if (!this.#mediaPlayAt) {
          // stop has been called on a non-played media
          return this
        }
        this.#mediaStopAt = opAt

        // compute play duration
        const durationMS =
          this.#mediaStopAt.getTime() - this.#mediaPlayAt.getTime()
        const duration = parseInt(durationMS / 1000)

        const payload = {
          id: this.id,
          mediaPlayDuration: duration,
          mediaPlayDurationMS: durationMS,
          mediaDuration: this.duration,
          mediaTitle: this.$metadata('title', 'unknown'),
        }

        // @todo
        // use only one route
        EventManager.emit('media_stop', payload)
        EventManager.emit('media_listen', payload)
      }
    } else if (process.env.NODE_ENV === 'development') {
      console.warn('just prevented an useless async realtime update attempt')
    }

    return this
  }

  async play(startPosition = undefined) {
    // if current state is "is playing" or if a play action is already
    // running, we do nothing
    if (this.#isPlayBooting || this.isPlaying) {
      return this
    }

    // set play booting status to true
    this.#isPlayBooting = true

    // there is no active howler instance or the current status is "unload"
    if (!this.#player || this.status === 'unload') {
      this.load()
    }

    // define status to "loading"
    this.status = 'loading'
    this.dispatchEvent('loading')()

    // set local volume from the MediaPlayer volume
    this.volume = MediaPlayer.volume

    if (startPosition) {
      this.#player.seek(startPosition)
    }

    if (MediaPlayer.isContent(this.#content, this.#episodeIndex) === false) {
      if (MediaPlayer.isPlaying === true) {
        MediaPlayer.stop()
      }
      MediaPlayer.setContent(this.#content, this.#episodeIndex)
    }

    try {
      if (Howler.ctx && Howler.ctx.state === 'suspended') {
        try {
          await Howler.ctx.resume()
        } catch (error) {
          if (window.AudioContext) {
            Howler.ctx = new AudioContext()
            await Howler.ctx.resume()
          }
        }
      }

      await this.#player.play()
      this.#isPlayBooting = false
    } catch (error) {
      this.#isPlayBooting = false
      this.status = 'error'
      this.dispatchEvent('error')(error)
      console.error(error)
      throw new CoreError('unable to play audio source')
    }

    return this
  }

  load(options) {
    if (this.#player || this.status === 'loading') {
      return this
    }

    try {
      this.status = 'loading'

      const player = new Howl({
        src: this.$data('value'),
        html5: true,
        preload: true,
        ...options,
      })

      this.#player = player
      this.#player.episode = this

      // trick to display track title when mobile is locked
      if (this.#player && this.#player._sounds && this.#player._sounds.length) {
        this.#player._sounds.forEach((sound, index) => {
          if (sound._node) {
            sound._node.id = `spoke-audioplayer-i-${index}`
            sound._node.setAttribute(
              'title',
              `${this.$metadata('title')} - ${this.#content.title}`
            )
          }
        })
      }

      HOWLER_EVENTS.forEach((eventName) => {
        this.#player.on(eventName, this.dispatchEvent(eventName))
      })

      this.#player.on('load', () => {
        this.computedAudioDuration = this.#player.duration()
        this.dispatchEvent('booted')()
      })

      const computeAndDispatchCurrentProgression = () => {
        this.dispatchEvent('timeupdate')(this.progress)
      }

      // not active currently
      const onPause = () => {
        this.asyncStatusAtomicUpdate('stop')
        this.status = 'pause'
      }

      // fired when the media is played
      // will fire a rt event
      const onPlay = () => {
        if (this.status !== 'play') {
          if (this.status === 'loading') {
            this.dispatchEvent('load')()
          }
          this.dispatchEvent('play')()
          this.hasBeenLaunched = true
          this.isLaunched = true

          let startPosition = this.currentTime

          // prevent browsers bufferized-fucked currentTime approximation
          if (startPosition <= 0.1) {
            startPosition = 0
          }

          this.asyncStatusAtomicUpdate('play', {
            media: {
              id: this.id,
              status: 'play',
              startPosition: startPosition * 1000,
            },
          })

          const dispatcher = () => {
            if (this.isPlaying) {
              computeAndDispatchCurrentProgression()
              window.requestAnimationFrame(dispatcher)
            }
          }

          window.requestAnimationFrame(dispatcher)

          this.status = 'play'
        }
      }

      // fired when the media is definitely stopped
      // will fire a rt event
      const onStop = () => {
        this.isLaunched = false
        this.asyncStatusAtomicUpdate('stop')
        nextTick(() => {
          this.unload()
        })
        this.status = 'unload'
      }

      this.#player.on('play', onPlay)

      // we unload the state of the webaudio component
      // to prevent external causes of issues (network...)
      // and allow client to manually retry
      this.#player.on('loaderror', (error) => {
        this.dispatchEvent('error')(error)
        this.unload()
        this.status = 'error'
      })

      this.#player.on('playerror', (error) => {
        this.dispatchEvent('error')(error)
        this.unload()
        this.status = 'error'
      })

      this.#player.on('pause', onPause)

      this.#player.on('seek', () => {
        computeAndDispatchCurrentProgression()
      })

      this.#player.on('stop', onStop)

      this.#player.on('end', () => {
        this.dispatchEvent('end')()
      })
    } catch (error) {
      this.status = 'error'
      console.error(error)
      this.emit('error', error)
      throw new CoreError('unable to load audio source')
    }

    return this
  }

  unload() {
    if (this.#player) {
      this.#player.stop()
      this.status = 'unload'
      this.#player &&
        this.#player._state !== 'unloaded' &&
        this.#player.unload()
      this.removeAllListeners()
      this.#player = null
    }

    return this
  }

  pause() {
    if (this.#player) {
      this.#player.pause()

      // if content is of type 'live'
      // stream must be STOPPED (and not paused)
      // the trick here:
      const mustBeUnloaded =
        this.content.$data('type') === 'live' &&
        (this.isError || this.isPlaying)

      if (mustBeUnloaded) {
        nextTick(() => {
          this.unload()
        })
      }
      this.status = 'pause'
    }

    return this
  }

  seek(time) {
    if (this.#player) {
      this.#player.seek(time)
    }

    return this
  }

  stop() {
    if (this.#player) {
      this.status = 'stop'
      this.#player.stop()
    }

    return this
  }
}

/**
 * ensure data is well formated (API-LTS compliant)
 * @param {object} data
 * @returns {boolean}
 */
CommonAudio.isValid = function (data) {
  // ensure data is an object and has a non empty value
  return isObject(data) && data.value
}

export default CommonAudio
